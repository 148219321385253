body {
  margin: 0;
}img {
  width: 500px;
}#particles-js {
  background: radial-gradient(circle, rgba(193, 196, 209, 1) 0%,
  rgba(3, 3, 29, 1) 100%);
  height: 100vh;
}#welcome {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}.scale-in-center {
  -webkit-animation: scale-in-center 1.5s cubic-bezier(0.250,
  0.460, 0.450, 0.940) 1s both;
  animation: scale-in-center 1.5s cubic-bezier(0.250, 0.460,
  0.450, 0.940) 1s both;
}@-webkit-keyframes scale-in-center {
  0% {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 1;
  }
  100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
  }
}@keyframes scale-in-center {
  0% {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 1;
  }
  100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
  }
}